(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/wallet-type/assets/javascripts/wallet-helpers.js') >= 0) return;  svs.modules.push('/components/tipsen/wallet-type/assets/javascripts/wallet-helpers.js');
"use strict";

const {
  walletType
} = svs.components.tipsen.wallet;
const isMarketplaceWallet = wallet => (wallet === null || wallet === void 0 ? void 0 : wallet.type) === walletType.WALLET_MARKETPLACE_PLAYER || (wallet === null || wallet === void 0 ? void 0 : wallet.type) === walletType.WALLET_MARKETPLACE_RETAILER;
setGlobal('svs.components.tipsen.wallet.walletHelpers', {
  isMarketplaceWallet
});

 })(window);